@import '../../styles/propertySets.css';

.root {
  display: none;

  @media (--viewportMedium) {
    display: flex;
    justify-content: flex-end;
    height: 57px;
    align-items: flex-end;
    padding: 13px 24px 0 24px;
  }

  @media (--viewportLarge) {
    padding: 0 36px;
  }
}

.paypalOnboardingButton {
  @apply --marketplaceButtonStylesPrimary;



}

.modalContainer {
  @apply --marketplaceModalBaseStyles;

  height: 100vh;

@media (--viewportMedium) {
  flex-basis: 576px;
  height: unset;
  padding: var(--modalPaddingMedium);
  background-color: var(--matterColorLight);
  margin-top: 12.5vh;
  margin-bottom: 12.5vh;
}

@media (--viewportLarge) {
  padding: 0;
  background-color: transparent;
  margin-top: 0;
  margin-bottom: 0;
}
}

.paypalDisableButton {
  @apply --marketplaceButtonStylesSecondary;
  padding-top: 0;


}

.tab {
  display: flex;
  align-items: flex-end;
  height: 100%;

  &:first-child {
    margin-left: 0;
  }
}
